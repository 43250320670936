import React from "react"
import { graphql } from "gatsby"

import GlobalStateProvider from "context/provider"
import Layout from "components/layout"
import SEO from "components/seo"
import Hero from "components/sections/about-us/hero"
import List from "components/sections/awards/list"
import Videos from "components/sections/press/videos"
import Trial from "components/common/trial"
import { seoTitleSuffix } from "config"

const AboutPage = ({ data }) => {
  const { frontmatter } = data.press.edges[0].node

  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: frontmatter.useSplashScreen ? false : true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }

  return (
    <GlobalStateProvider initialState={globalState}>
     <Layout headerContent={data.header.edges} footerContent={data.footer.edges} lang="fr">
        <SEO
          frontmatter={frontmatter}
          lang="fr"
        />
        <Hero content={data.hero.edges} />
        <Videos content={data.videos.edges} />
        <div className="gradient">
          <List content={data.list.edges} />
        </div>
        
      </Layout>
    </GlobalStateProvider>
  )
}


export default AboutPage

export const pageQuery = graphql`
  {
    press: allMdx(filter: { fileAbsolutePath: { regex: "/press/fr/press/" } }) {
      edges {
        node {
          frontmatter {
            title
            url
            description
            keywords
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/press/fr/hero/" } }) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            teamPhoto {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
              }
            }
            mdxbgImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
              }
            }
          }
        }
      }
    }
    videos: allMdx(filter: { fileAbsolutePath: { regex: "/press/fr/videos/" } }) {
      edges {
        node {
          exports {
            items {
              title
              text
              video
              author
            }
          }
          frontmatter {
            title
          }
        }
      }
    }
    list: allMdx(filter: { fileAbsolutePath: { regex: "/press/fr/list/" } }) {
      edges {
        node {
          exports {
            items {
              company
              prize
              date
              link
              logo {
                childImageSharp {
                  gatsbyImageData(quality: 90)
                }
              }
            }
          }
          frontmatter {
            title
            subtitle
          }
        }
      }
    }
    footer: allMdx(filter: { fileAbsolutePath: { regex: "/layout/fr/footer/" } }) {
      edges {
        node {
          body
          frontmatter {
            label
            title
            titlePrefix
            subtitle
            subtitlePrefix
            buttonBookingText
            buttonBookingLink
            buttonSignupText
            buttonSignupLink
            copyRight
          }
          exports {
            items {
              name
              links {
                text
                link
                external
              }
            }
          }
        }
      }
    }
    header: allMdx(filter: { fileAbsolutePath: { regex: "/layout/fr/header/" } }) {
      edges {
        node {
          frontmatter {
            demoButtonText
            demoButtonLink
            registerButtonText
            registerButtonLink
            bookingButtonText
            bookingButtonLink
          }
          exports {
            items {
              name
              modal {
                left {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                }
                right {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                  media {
                    name
                    photo
                    alt
                    text
                    link
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
